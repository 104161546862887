/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

ion-icon {
    cursor: pointer;
  }
ion-icon:hover {
    cursor: pointer;
}
/*
ion-input.label100px {
    color:black;
    min-height: 20px !important;
}*/

ion-col.label  {
    max-width:120px !important;
    font-weight: bold;
    color: var(--ion-color-primary);
    background: rgba(var(--ion-color-primary-rgb), 0.14);
    display: flex;
    align-items: center;
}

ion-grid.oddeven ion-row:nth-child(even) ion-col:not(.label) {
    background: #f1f1f1
}

ion-grid.oddeven ion-row:nth-child(odd) ion-col:not(.label) {
    background: #FFF
}

ion-reorder:nth-child(even) ion-row.invoiceline {
    background: #f1f1f1
}

ion-reorder:nth-child(odd) ion-row.invoiceline {
    background: #FFF
}

ion-grid {
    color:black;
}

ion-row.invoiceheader, ion-row.tableheader {
    font-weight: bold;
    background: rgba(var(--ion-color-primary-rgb), 0.14);
    color: var(--ion-color-primary);
}

.autocompletelist[hidden] {
    display:none;
}

.autocompletelist {
    z-index:999;
    top: -100px;    
    position: absolute;
    //width: 200px;
    /*height: 200px;*/
    border: 1px solid lightgray;
}

ion-input {
    --placeholder-color: rgba(var(--ion-color-primary-rgb), 0.75) !important;
}

ion-card {
    background-color: #fafafa;
    margin-bottom:4em;
    border: 1px solid lightgray;
}

ion-row.line, ion-row.tableline {
    border-bottom:1px solid rgb(231, 233, 237);
}

ion-row.header, ion-row.tableheader {
    font-weight: bold;
    //background: #ccc;
}

ion-col {
    padding:1px;

}
ion-input {
    font-size:13px;
}

ion-row.header ion-col, ion-row.tableheader ion-col {
    text-align: center !important;
    border-right:1px solid lightgray;
}

.invoice {
    margin:20px;
    border:1px solid rgb(231, 233, 237);
    border-radius: 5px;
}